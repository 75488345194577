export default function hospitalDetails(hospitalDetails = {}, action) {
  switch (action.type) {
    case "GET_HOSPITAL_DETAILS": {
      hospitalDetails = action.payload;
      return hospitalDetails;
    }
    default: {
      return hospitalDetails;
    }
  }
}
