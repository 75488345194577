import React from "react";

const ErrorBoundaryUI = () => {
  const onRefresh = () => {
    window.location.reload();
  };
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card text-center p-2">
        <img
          src="/error-boundary.png"
          alt="Error"
          className="card-img-top mx-auto mt-4"
          style={{ height: "100px", width: "fit-content" }}
        />
        <div className="card-body">
          <h5 className="card-title">Oops! Something went wrong</h5>
          <p className="card-text">
            We're sorry, but an error occurred.<br/>Please contact support.
          </p>
          <button className="btn btn-primary" onClick={onRefresh}>
            Refresh Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundaryUI;
