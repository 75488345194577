import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import store from "./app/redux/store/index";
import { Provider } from "react-redux";
import ErrorBoundary from "./app/components/error-boundary/ErrorBoundary";

ReactDOM.render(
  <BrowserRouter>
    <ErrorBoundary>
      <Provider store={store}>
        <App />
      </Provider>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
