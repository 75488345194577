export default function hospitalList(hospitalList = [], action) {
  switch (action.type) {
    case "GET_ALL_HOSPITALS_LIST": {
      hospitalList = action.payload;
      return hospitalList;
    }
    default: {
      return hospitalList;
    }
  }
}
