//dev
// const Constants = {
//   BASE_URL: "https://localhost:44365/api/",
//   GOOGLE_CLIENT_ID: "113873161933-1j99gn4fvnm1832i61m3lg8d6gfleb0l.apps.googleusercontent.com",
//   GOOGLE_AUTH_CALLBACK_URL: "https://patient.healthstation.ng",
//   FACEBOOK_APP_ID: "1063855254113074",
//   GoogleMap: "AIzaSyCBD--C0DN8SqetZXxl1KmRuMCdJObR0u0",
//   GOOGLE_ANALYTICS_TRACKING_ID: "UA-169658542-1",
//   BANK3D_MERCHANT_ID: "B3DD4DloZ2MGZl2JWyhjPoNA",
//   VOIP_CLIENT_URL: "https://calls.healthstation.ng",
//   WEB_PAYMENT_URL: "https://me.healthstation.ng/",
//   PAGE_TITLES: {
//     "/encounters/timeline": "Medical History",
//     "/appointments": "Appointments",
//     "/admin/hospitals": "Hospitals",
//     "/patient/verify": "Add Hospital",
//     "/patient/verify-otp": "Verify OTP",
//     "/account/profile": "Profile",
//     "/patient/register": "Hospital Registration",
//     "/get-started": "Get Started",
//     "/payments": "Payments",
//     "/invoice-payment": "Invoice Payments",
//     "/deposit-payment": "Deposit Payments",
//     "/final-payment": "Final Payments",
//     "/patient/switchhospital": "Switch Hospital",
//     "/wallet": "Wallet",
//   },
//   RELATIONSHIPS: ['Son', 'Daughter', 'Wife', 'Husband', 'Nephew', 'Niece', 'Sister', 'Brother', 'Aunty', 'Uncle', 'Grand Mother', 'Grand Father', 'Mother', 'Father', 'Guardian', 'Neighbor', 'Friend'],
// }

import { EnvConfigSecrets } from "./config/env";

//test
const Constants = {
  BASE_URL: EnvConfigSecrets.instance.getApiBaseUrl(),
  GOOGLE_CLIENT_ID:
    "113873161933-1j99gn4fvnm1832i61m3lg8d6gfleb0l.apps.googleusercontent.com",
  GOOGLE_AUTH_CALLBACK_URL: "https://patient.healthstation.ng",
  FACEBOOK_APP_ID: "1063855254113074",
  GoogleMap: "AIzaSyCBD--C0DN8SqetZXxl1KmRuMCdJObR0u0",
  GOOGLE_ANALYTICS_TRACKING_ID: "UA-169658542-1",
  BANK3D_MERCHANT_ID: EnvConfigSecrets.instance.getFastCarePaymentMerchantKey(),
  VOIP_CLIENT_URL: EnvConfigSecrets.instance.getConsultationCallBaseUrl(),
  WEB_PAYMENT_URL: "https://me.healthstation.ng/",
  PAGE_TITLES: {
    "/encounters/timeline": "Medical History",
    "/appointments": "Appointments",
    "/admin/hospitals": "Hospitals",
    "/patient/verify": "Add Hospital",
    "/patient/verify-otp": "Verify OTP",
    "/account/profile": "Profile",
    "/patient/register": "Hospital Registration",
    "/get-started": "Get Started",
    "/payments": "Payments",
    "/invoice-payment": "Invoice Payments",
    "/deposit-payment": "Deposit Payments",
    "/final-payment": "Final Payments",
    "/patient/switchhospital": "Switch Hospital",
    "/wallet": "Wallet",
  },
  RELATIONSHIPS: [
    "Son",
    "Daughter",
    "Wife",
    "Husband",
    "Nephew",
    "Niece",
    "Sister",
    "Brother",
    "Aunty",
    "Uncle",
    "Grand Mother",
    "Grand Father",
    "Mother",
    "Father",
    "Guardian",
    "Neighbor",
    "Friend",
  ],
};

//live
// const Constants = {
//   BASE_URL: "https://server.medicall.ng/api/",
//   GOOGLE_CLIENT_ID: "113873161933-1j99gn4fvnm1832i61m3lg8d6gfleb0l.apps.googleusercontent.com",
//   GOOGLE_AUTH_CALLBACK_URL: "https://medicall.ng",
//   FACEBOOK_APP_ID: "1063855254113074",
//   GoogleMap: "AIzaSyCBD--C0DN8SqetZXxl1KmRuMCdJObR0u0",
//   GOOGLE_ANALYTICS_TRACKING_ID: "UA-169658542-1",
//   BANK3D_MERCHANT_ID: "B3DD4DloZ2MGZl2JWyhjPoNA",
//   VOIP_CLIENT_URL: "https://calls.healthstation.ng",
//   WEB_PAYMENT_URL: "https://me.healthstation.ng/",
//   PAGE_TITLES: {
//     "/encounters/timeline": "Medical History",
//     "/appointments": "Appointments",
//     "/admin/hospitals": "Hospitals",
//     "/patient/verify": "Add Hospital",
//     "/patient/verify-otp": "Verify OTP",
//     "/account/profile": "Profile",
//     "/patient/register": "Hospital Registration",
//     "/get-started": "Get Started",
//     "/payments": "Payments",
//     "/invoice-payment": "Invoice Payments",
//     "/deposit-payment": "Deposit Payments",
//     "/final-payment": "Final Payments",
//     "/patient/switchhospital": "Switch Hospital",
//     "/wallet": "Wallet",
//   },
//   RELATIONSHIPS: [
//     "Son",
//     "Daughter",
//     "Wife",
//     "Husband",
//     "Nephew",
//     "Niece",
//     "Sister",
//     "Brother",
//     "Aunty",
//     "Uncle",
//     "Grand Mother",
//     "Grand Father",
//     "Mother",
//     "Father",
//     "Guardian",
//     "Neighbor",
//     "Friend",
//   ],
// }

export default Constants;
