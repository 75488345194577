import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { logoutUser } from "../../modules/auth.module";
import { loggedIn } from "../../modules/auth.module";
import { useHistory } from "react-router-dom";
import { AppContext } from "../../AppContext";

const MyAccount = (props) => {
  const history = useHistory();
  const { profilePicture, user } = useContext(AppContext);
  const userName = user && user.name ? user.name : "";

  const handleSignOut = (e) => {
    e.preventDefault();
    logoutUser();
  };

  return (
    <>
      {loggedIn() ? (
        <ul className="navbar-nav navbar-nav-right ml-lg-auto justify-content-end">
          <li
            className="nav-item nav-profile border-0"
            style={{ border: "none" }}
          >
            <Dropdown alignRight>
              <Dropdown.Toggle
                variant="secondary"
                className="nav-link bg-transparent"
              >
                <span className="profile-text text-format mr-1">
                  Hi, {userName}
                </span>
                <img
                  className="img-sm rounded-circle"
                  src={profilePicture}
                  alt="Profile"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
                {history.location.pathname === "/" && (
                  <Dropdown.Item
                    className="dropdown-item preview-item d-flex align-items-center border-0 mt-2"
                    href="/encounters/timeline"
                  >
                    My Dashboard
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center border-0 mt-2"
                  href="/account/profile"
                >
                  My Account
                </Dropdown.Item>
                <Dropdown.Item
                  className="dropdown-item preview-item d-flex align-items-center border-0"
                  onClick={handleSignOut}
                >
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      ) : (
        ""
        // <ul className="navbar-nav ml-auto right-items justify-content-end">
        //   <li className="nav-item">
        //     {/* <span className="nav-link text-format">Login</span> */}
        //     <button
        //       className="login-btn text-format"
        //       type="button"
        //       onClick={() => history.push("/account/login")}
        //     >
        //       Sign in
        //     </button>
        //   </li>
        //   <li className="nav-item">
        //     <button
        //       className="btn sign-up-btn"
        //       type="button"
        //       onClick={() => history.push("/account/signup")}
        //     >
        //       Get Started
        //     </button>
        //   </li>
        // </ul>
      )}
    </>
  );
};

export default MyAccount;
