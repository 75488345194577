import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Navbar from "./components/layout/Navbar";
import Sidebar from "./components/layout/Sidebar";
import Footer from "./components/layout/Footer";
import "./api/axios";
import { AppProvider } from "./AppContext";
import { pingApi } from "./api/api";
import {
  InitializeGA,
  TrackPageViewGA,
} from "./components/tools/GoogleAnalytics";
import { loggedIn } from "./modules/auth.module";
import Constants from "./AppConstants";
import "antd/dist/antd.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    if (this.props.location.pathname !== "/account/login" && loggedIn()) {
      this.pingApi();
    }
    InitializeGA();
  }

  componentDidMount() {
    this.onRouteChanged();
  }

  pingApi = async () => {
    await pingApi();
  };

  render() {
    let navbarComponent = !this.state.isFullPageLayout ? <Navbar /> : "";
    let sidebarComponent = !this.state.isFullPageLayout ? <Sidebar /> : "";
    let footerComponent = !this.state.isFullPageLayout ? <Footer /> : "";
    // let hospitalInfoComponent = !this.state.isFullPageLayout ? <HospitalInfo /> : '';
    return (
      <AppProvider>
        <div className="container-scroller">
          {navbarComponent}
          {/* {window.location.pathname === "/account/login" ? } */}
          <div className="position-relative">{sidebarComponent}</div>
          <div className="d-flex">
            <div
              className={
                window.location.pathname === "/account/login" ||
                window.location.pathname === "/account/signup" ||
                window.location.pathname === "/reset-password" ||
                window.location.pathname === "/account/reset-password" ||
                window.location.pathname === "/account/reset-password-request"
                  ? "container-fluid page-body-wrapper"
                  : "container-fluid page-body-wrapper content-wrapper-m"
              }
            >
              <div className="main-panel">
                <div className="content-wrapper">
                  {/* {hospitalInfoComponent} */}
                  <AppRoutes />
                </div>

                {footerComponent}
              </div>
            </div>
          </div>
        </div>
      </AppProvider>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    console.log("ROUTE CHANGED");
    localStorage.setItem(
      "PageTitle",
      Constants.PAGE_TITLES[this.props.location.pathname]
    );
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [
      "/account/login",
      "/account/signup",
      "/account/reset-password-request",
      "/reset-password",
      "/user-pages/register-2",
      "/user-pages/lockscreen",
      "/error-pages/error-404",
      "/error-pages/error-500",
      "/general-pages/landing-page",
    ];
    for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
      if (this.props.location.pathname === fullPageLayoutRoutes[i]) {
        this.setState({
          isFullPageLayout: true,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.add("full-page-wrapper");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document
          .querySelector(".page-body-wrapper")
          .classList.remove("full-page-wrapper");
      }
    }
    TrackPageViewGA(this.props.location.pathname);
  }
}

export default withRouter(App);
