export default function userHospitalId(userHospitalId = "", action) {
  switch (action.type) {
    case "SET_USER_HOSPITAL_ID": {
      userHospitalId = action.payload;
      return userHospitalId;
    }
    default: {
      return userHospitalId;
    }
  }
}
