import {
  login,
  loginWithFacebook,
  loginWithGoogle,
  requestResetPassword,
  resetPassword,
  signup,
  getWalletDetails,
  getHospitalLogo,
  hospitalSettings,
} from "../api/api";

export const signUpUser = async (data, callbacks) => {
  // if (data.phoneNumber.toString().length < 11)
  //   data.phoneNumber = "0" + data.phoneNumber.toString();
  let result = await signup(data);
  if (result.statusCode) {
    if (result.statusCode === 200) {
      saveUserProfile(result, callbacks.setUser);
      // saveHospitalProfile(result);
      saveToken(result.data.authToken);
      return { successful: true, message: "Success" };
    }
    if (result.statusCode === 400) {
      return { successful: false, message: result.data };
    }
  }
  return { successful: false, message: "Unable to log in at the moment" };
};

export const loginUser = async (data, callbacks) => {
  let result = await login(data);
  if (result.statusCode) {
    if (result.statusCode === 200) {
      saveUserProfile(result, callbacks.setUser);
      saveProfilePicture(result.data.profileImage, callbacks.setProfilePicture);
      saveHospitalProfile(
        {
          hospitalNumber: result.data.hospitalID,
          hospitalName: result.data.hospitalName,
          hospitalId: result.data.hospital,
          hospitalCode: result.data.hospitalCode,
          patientId: result.data.patientId,
        },
        callbacks.setHospitalProfile
      );
      saveToken(result.data.authToken);
      saveEmailVerified(result.data.emailVerified);
      savePhoneNumberVerified(result.data.phoneNumberVerified);
      saveWebsiteUrl(result.data.websiteUrl);

      //Call get hospitalLogo API
      const retrieveHospitalLogo = await getHospitalLogo(result.data.hospital);
      saveHospitalLogoToLocalStorage(retrieveHospitalLogo.data);

      const userWalletDetails = await getWalletDetails(
        result.data.patientId,
        result.data.hospital
      );
      if (userWalletDetails.statusCode === 200) {
        saveUserWalletDetails(
          userWalletDetails.data,
          callbacks.setUserWalletDetails
        );
      }
      const getHopSettings = await hospitalSettings(result.data.hospital);
      if (getHopSettings.statusCode === 200) {
        saveHospitalSettings(getHopSettings.data, result.data.hospital);
      }

      return { successful: true, message: "Success" };
    }
    if (result.statusCode === 400) {
      return { successful: false, message: result.data };
    }
  }
  return { successful: false, message: "Unable to log in at the moment" };
};

export const loginUserWithGoogle = async (data, callbacks) => {
  let result = await loginWithGoogle(data);
  if (result.statusCode) {
    if (result.statusCode === 200) {
      saveUserProfile(result, callbacks.setUser);
      saveProfilePicture(result.data.profileImage, callbacks.setProfilePicture);
      saveHospitalProfile(
        {
          hospitalNumber: result.data.hospitalID,
          hospitalName: result.data.hospitalName,
          hospitalId: result.data.hospital,
        },
        callbacks.setHospitalProfile
      );
      saveToken(result.data.authToken);

      //Call get hospitalLogo API
      const retrieveHospitalLogo = await getHospitalLogo(result.data.hospital);
      saveHospitalLogoToLocalStorage(retrieveHospitalLogo.data);

      const userWalletDetails = await getWalletDetails(
        result.data.patientId,
        result.data.hospital
      );
      if (userWalletDetails.statusCode === 200) {
        saveUserWalletDetails(
          userWalletDetails.data,
          callbacks.setUserWalletDetails
        );
      }
      return { successful: true, message: "Success" };
    }
    if (result.statusCode === 400) {
      return { successful: false, message: result.data };
    }
  }
  return { successful: false, message: "Unable to log in at the moment" };
};

export const loginUserWithFacebook = async (data, callbacks) => {
  let result = await loginWithFacebook(data);
  if (result.statusCode) {
    if (result.statusCode === 200) {
      saveUserProfile(result, callbacks.setUser);
      saveProfilePicture(
        result.data.profileImage,
        callbacks.setUserWalletDetails
      );
      saveHospitalProfile(
        {
          hospitalNumber: result.data.hospitalID,
          hospitalName: result.data.hospitalName,
          hospitalId: result.data.hospital,
        },
        callbacks.setHospitalProfile
      );
      saveToken(result.data.authToken);

      //Call get hospitalLogo API
      const retrieveHospitalLogo = await getHospitalLogo(result.data.hospital);
      saveHospitalLogoToLocalStorage(retrieveHospitalLogo.data);

      const userWalletDetails = await getWalletDetails(
        result.data.patientId,
        result.data.hospital
      );
      if (userWalletDetails.statusCode === 200) {
        saveUserWalletDetails(
          userWalletDetails.data,
          callbacks.setUserWalletDetails
        );
      }
      return { successful: true, message: "Success" };
    }
    if (result.statusCode === 400) {
      return { successful: false, message: result.data };
    }
  }
  return { successful: false, message: "Unable to log in at the moment" };
};

export const requestResetUserPassword = async (email) => {
  let result = await requestResetPassword(email);
  if (result.statusCode) {
    if (result.statusCode === 200 || result.statusCode === 202) {
      return { successful: true, message: result.data };
    }
    if (result.statusCode === 400) {
      return { successful: false, message: result.data };
    }
  }
  return {
    successful: false,
    message: "Unable to complete reset password request at the moment",
  };
};

export const resetUserPassword = async (data) => {
  let result = await resetPassword(data);
  if (result.statusCode) {
    if (result.statusCode === 200) {
      return { successful: true, message: result.data };
    }
    if (result.statusCode === 400) {
      return { successful: false, message: result.data };
    }
  }
  return {
    successful: false,
    message: "Unable to reset password at the moment",
  };
};

export const logoutUser = () => {
  window.localStorage.removeItem("token");
  window.localStorage.removeItem("user");
  window.localStorage.removeItem("hospital");
  window.localStorage.removeItem("profilePicture");
  window.localStorage.removeItem("hospitalSettings");
  window.localStorage.removeItem("hospitalID");
  window.localStorage.removeItem("walletDetails");
  window.localStorage.removeItem("hospitalLogo");
  window.localStorage.removeItem("PendingRegistration");
  window.localStorage.removeItem("emailVerified");
  window.localStorage.removeItem("phoneNumberVerified");
  window.localStorage.removeItem("websiteUrl");
  window.sessionStorage.removeItem("PendingRegistration");
  window.location.href = "/";
};

export const loggedIn = () => {
  const token = getAuthToken();
  if (!token) {
    return false;
  }
  return true;
};

export const isAdmin = () => {
  const user = JSON.parse(window.localStorage.getItem("user"));
  if (user !== null) return user.role === "Admin";
  return false;
};

export const saveUserProfile = (result, setUser) => {
  const user = {
    email: result.data.email,
    id: result.data.id,
    firstName: result.data.firstName,
    lastName: result.data.lastName,
    name: result.data.name,
    phoneNumber: result.data.phoneNumber,
    role: result.data.userRole,
    middleName: result.data.middleName,
    gender: result.data.gender,
    nextOfKinPhone: result.data.nextOfKinPhone,
    patientId: result.data.patientId,
  };
  localStorage.setItem("user", JSON.stringify(user));
  setUser(user);
};

export const saveHospitalProfile = (hospital, setHospitalProfile) => {
  localStorage.setItem("hospital", JSON.stringify(hospital));
  setHospitalProfile(hospital);
};

export const saveUserWalletDetails = (data, setUserWalletDetails) => {
  localStorage.setItem("walletDetails", JSON.stringify(data));
  setUserWalletDetails(data);
};

export const saveHospitalSettings = (hospSettings, hospId) => {
  window.localStorage.setItem("hospitalSettings", JSON.stringify(hospSettings));
  window.localStorage.setItem("hospitalID", hospId);
};

export const getUserWalletDetails = () => {
  return JSON.parse(window.localStorage.getItem("walletDetails"));
};

export const getHospitalProfile = () => {
  return JSON.parse(window.localStorage.getItem("hospital"));
};

const saveToken = (token) => {
  localStorage.setItem("token", token);
};

export const getUserProfile = () => {
  return JSON.parse(window.localStorage.getItem("user"));
};

export const getAuthToken = () => {
  return window.localStorage.getItem("token");
};

export const saveProfilePicture = (picture, setProfilePicture) => {
  if (picture !== null && picture !== undefined && picture !== "") {
    window.localStorage.setItem(
      "profilePicture",
      "data:image/png;base64," + picture
    );
    setProfilePicture("data:image/png;base64," + picture);
  }
};

export const getProfilePicture = () => {
  const profilePicture = window.localStorage.getItem("profilePicture");
  if (profilePicture !== undefined && profilePicture !== null) {
    return profilePicture;
  }
  return require("../../assets/images/avatar.png");
};

export const saveHospitalLogoToLocalStorage = (logo) => {
  if (
    logo !== null &&
    logo !== undefined &&
    logo !== "" &&
    typeof logo === "string"
  ) {
    window.localStorage.setItem(
      "hospitalLogo",
      "data:image/png;base64," + logo
    );
  }
};

export const getHospitalLogoFromLocalStorage = () => {
  const hospitalLogo = window.localStorage.getItem("hospitalLogo");
  if (hospitalLogo !== undefined && hospitalLogo !== null) {
    return hospitalLogo;
  } else {
    return require("../../assets/images/hospital.png");
  }
};

export const saveEmailVerified = (value) => {
  window.localStorage.setItem("emailVerified", JSON.stringify(value));
};

export const getEmailVerified = () => {
  return window.localStorage.getItem("emailVerified")
    ? JSON.parse(window.localStorage.getItem("emailVerified"))
    : null;
};

export const savePhoneNumberVerified = (value) => {
  window.localStorage.setItem("phoneNumberVerified", JSON.stringify(value));
};

export const getPhoneNumberVerified = () => {
  return window.localStorage.getItem("phoneNumberVerified")
    ? JSON.parse(window.localStorage.getItem("phoneNumberVerified"))
    : null;
};

export const getWebsiteUrl = () => {
  return window.localStorage.getItem("websiteUrl")
    ? JSON.parse(window.localStorage.getItem("websiteUrl"))
    : null;
};

export const saveWebsiteUrl = (value) => {
  window.localStorage.setItem("websiteUrl", JSON.stringify(value));
};
