export default function hospitalListLoading(
  hospitalListLoading = true,
  action
) {
  switch (action.type) {
    case "HOSPITAL_LISTS_LOADING": {
      hospitalListLoading = action.payload;
      return hospitalListLoading;
    }
    default: {
      return hospitalListLoading;
    }
  }
}
