import config from "../AppConstants";
import axios from "axios";

function handleResponse(response) {
  if (response.results) {
    return response.results;
  }
  if (response.data !== undefined) {
    return { statusCode: response.status, data: response.data };
  }
  return response;
}

function handleError(error) {
  console.log(error);
  if (error.response) {
    return { statusCode: error.response.status, data: error.response.data };
  }
  return error;
}

export const pingApi = async () => {
  return await axios
    .get(`${config.BASE_URL}Account/ping`)
    .then(handleResponse)
    .catch(handleError);
};

export const getHospitals = async () => {
  return await axios
    .get(`${config.BASE_URL}Hospitals`)
    .then(handleResponse)
    .catch(handleError);
};

export const verifyPatient = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Hospitals/verify/patient`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const verifyOTP = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Hospitals/verify/otp`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const getEncounters = async (hospitalId, activity) => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/encounters`, {
      params: { hospitalId, activity },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const resendOTP = async () => {
  return await axios
    .post(`${config.BASE_URL}Hospitals/verify/resendotp`, {})
    .then(handleResponse)
    .catch(handleError);
};

export const signup = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Account/create`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const login = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Auth/login`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const loginWithGoogle = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Auth/google`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const loginWithFacebook = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Auth/facebook`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const requestResetPassword = async (email) => {
  return await axios
    .get(`${config.BASE_URL}Auth/password/reset`, { params: { email } })
    .then(handleResponse)
    .catch(handleError);
};

export const resetPassword = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Auth/password/reset`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const changePassword = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Auth/password/change`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const getPatientData = async (activityNumber) => {
  return await axios
    .get(`${config.BASE_URL}Report/Prescription`, {
      params: { activityNumber },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const getFeedBack = async (activityId) => {
  return await axios
    .get(`${config.BASE_URL}Feedback`, { params: { activityId } })
    .then(handleResponse)
    .catch(handleError);
};

export const getOneFeedBack = async (id, imageName) => {
  return await axios
    .get(`${config.BASE_URL}Feedback/${id}/images/${imageName}`)
    .then(handleResponse)
    .catch(handleError);
};

export const postFeedBack = async (userFeedback) => {
  const headerConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return await axios
    .post(`${config.BASE_URL}Feedback`, userFeedback, headerConfig)
    .then(handleResponse)
    .catch(handleError);
};

export const uploadProfilePicture = async (metadata) => {
  const headerConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  return await axios
    .post(`${config.BASE_URL}Account/Imageupload`, metadata, headerConfig)
    .then(handleResponse)
    .catch(handleError);
};

export const getOutlets = async () => {
  return await axios
    .get(`${config.BASE_URL}Outlet`, { body: { outletType: "radiology" } })
    .then(handleResponse)
    .catch(handleError);
};

export const callForZoomToken = async (accessCode) => {
  return await axios
    .get(`${config.BASE_URL}ZoomHandle/GetZoomAccessToken`, {
      params: { accessCode },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const createZoomMeeting = async (meetingRequest) => {
  return await axios
    .post(`${config.BASE_URL}ZoomHandle/CreateMeeting`, meetingRequest)
    .then(handleResponse)
    .catch(handleError);
};

export const getAppointments = async (hospitalId) => {
  return await axios
    .get(`${config.BASE_URL}Appointment`, { params: { hospitalId } })
    .then(handleResponse)
    .catch(handleError);
};

export const getClinics = async (hospitalId) => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/Clinics`, {
      params: { hospitalId },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const getClinicsForRegistration = async (hospitalId) => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/Clinics`, {
      params: { hospitalId, category: "registration" },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const getRegistrationPrice = async (
  hospitalId,
  clinicId,
  dateOfBirth
) => {
  return await axios
    .get(`${config.BASE_URL}hospitals/${hospitalId}/registrationprice`, {
      params: { clinicId, dateOfBirth },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const getConsultationPrice = async (
  hospitalId,
  clinicId,
  consultationType
) => {
  return await axios
    .get(`${config.BASE_URL}hospitals/${hospitalId}/consultationprice`, {
      params: { clinicId, consultationType },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const getSpecialtyUnits = async (hospitalId, clinicId) => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/SpecialtyUnits`, {
      params: { hospitalId, clinicId },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const createAppointment = async (appointment) => {
  return await axios
    .post(`${config.BASE_URL}Appointment`, appointment)
    .then(handleResponse)
    .catch(handleError);
};

export const appointmentAvailability = async (appointmentID) => {
  return await axios
    .post(`${config.BASE_URL}appointment/${appointmentID}/available`)
    .then(handleResponse)
    .catch(handleError);
};

export const updatePaymentForAppointment = async (paymentDetails) => {
  return await axios
    .post(
      `${config.BASE_URL}appointment/${paymentDetails.Id}/paymentstatus`,
      paymentDetails
    )
    .then(handleResponse)
    .catch(handleError);
};

export const rescheduleAppointment = async (appointmentID, appointmentDate) => {
  return await axios
    .post(
      `${config.BASE_URL}appointment/${appointmentID}/reschedule`,
      appointmentDate
    )
    .then(handleResponse)
    .catch(handleError);
};

export const cancelAppointment = async (appointmentID) => {
  return await axios
    .post(`${config.BASE_URL}appointment/${appointmentID}/cancel`)
    .then(handleResponse)
    .catch(handleError);
};

export const validateAppointmentDate = async (appointment) => {
  return await axios
    .post(`${config.BASE_URL}Appointment/validate/date`, appointment)
    .then(handleResponse)
    .catch(handleError);
};

export const hospitalSettings = async (hospitalID) => {
  return await axios
    .get(`${config.BASE_URL}hospitals/${hospitalID}/settings`)
    .then(handleResponse)
    .catch(handleError);
};

export const getInvoiceDetails = async (invoiceNumber, hospitalId) => {
  return await axios
    .post(
      `${config.BASE_URL}bills/lookup/invoice/${invoiceNumber}?hospitalId=${hospitalId}
`,
      { hospitalId: hospitalId }
    )
    .then(handleResponse)
    .catch(handleError);
};

export const intializeInvoicePayment = async (reqBody) => {
  return await axios
    .post(`${config.BASE_URL}bills/bank3d/finalpayment/init`, reqBody)
    .then(handleResponse)
    .catch(handleError);
};

export const verifyInvoicePayment = async (reference, hospitalId) => {
  return await axios
    .post(
      `${config.BASE_URL}bills/bank3d/invoice/${reference}/verify`,
      hospitalId
    )
    .then(handleResponse)
    .catch(handleError);
};

export const getPatientDetailsForDeposit = async (patientId, hospitalId) => {
  return await axios
    .post(
      `${config.BASE_URL}bills/lookup/deposit/${patientId}?hospitalId=${hospitalId}`
    )
    .then(handleResponse)
    .catch(handleError);
};

export const initializeDepositPayment = async (reqBody) => {
  return await axios
    .post(`${config.BASE_URL}bills/bank3d/deposit/init`, reqBody)
    .then(handleResponse)
    .catch(handleError);
};

export const verifyDepositPayment = async (reference, hospitalId) => {
  return await axios
    .post(
      `${config.BASE_URL}bills/bank3d/deposit/${reference}/verify `,
      hospitalId
    )
    .then(handleResponse)
    .catch(handleError);
};

export const getFinalPaymentDetails = async (patientId, hospitalId) => {
  return await axios
    .post(
      `${config.BASE_URL}bills/lookup/finalpayment/${patientId}?hospitalId=${hospitalId}`,
      {
        hospitalId: hospitalId,
      }
    )
    .then(handleResponse)
    .catch(handleError);
};

export const intializeFinalPayment = async (reqBody) => {
  return await axios
    .post(`${config.BASE_URL}bills/bank3d/finalpayment/init`, reqBody)
    .then(handleResponse)
    .catch(handleError);
};

export const verifyFinalPayment = async (reference, hospitalId) => {
  return await axios
    .post(
      `${config.BASE_URL}bills/bank3d/finalpayment/${reference}/verify`,
      hospitalId
    )
    .then(handleResponse)
    .catch(handleError);
};

export const getHospitalSubscriptions = async () => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/HospitalSubscription`)
    .then(handleResponse)
    .catch(handleError);
};

export const getAllHospitals = async () => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/all`)
    .then(handleResponse)
    .catch(handleError);
};

export const getHospitalLogo = async (id) => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/${id}/logo`)
    .then(handleResponse)
    .catch(handleError);
};

export const createHospital = async (hospital) => {
  return await axios
    .post(`${config.BASE_URL}Hospitals`, hospital)
    .then(handleResponse)
    .catch(handleError);
};

export const updateHospital = async (id, hospital) => {
  return await axios
    .put(`${config.BASE_URL}Hospitals/${id}`, hospital)
    .then(handleResponse)
    .catch(handleError);
};

export const initiatePayment = async (paymentRequest) => {
  return await axios
    .post(`${config.BASE_URL}payment/bank3d/initiate`, paymentRequest)
    .then(handleResponse)
    .catch(handleError);
};
export const verifyPayment = async (reference) => {
  return await axios
    .post(`${config.BASE_URL}Payment/bank3d/verify/${reference}`, null)
    .then(handleResponse)
    .catch(handleError);
};

export const verifyRegistrationPayment = async (reference) => {
  return await axios
    .post(`${config.BASE_URL}payment/bank3d/verify/${reference}`)
    .then(handleResponse)
    .catch(handleError);
};

export const getProfile = async () => {
  return await axios
    .get(`${config.BASE_URL}account/profile`)
    .then(handleResponse)
    .catch(handleError);
};

export const updateUser = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Account/update`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const pingHospital = async (hospitalId) => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/${hospitalId}/ping`)
    .then(handleResponse)
    .catch(handleError);
};

export const registerPatient = async (data) => {
  return await axios
    .post(`${config.BASE_URL}Hospitals/register`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const retrieveReference = async (serviceType, hospitalId) => {
  return await axios
    .post(
      `${config.BASE_URL}Payment/retrieve/${serviceType}/${hospitalId}`,
      null
    )
    .then(handleResponse)
    .catch(handleError);
};

export const getHospitalById = async (hospitalId) => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/${hospitalId}`)
    .then(handleResponse)
    .catch(handleError);
};

export const getCountries = async () => {
  return await axios
    .get(`${config.BASE_URL}Common/countries`)
    .then(handleResponse)
    .catch(handleError);
};

export const getStatesByCountry = async (CountryId) => {
  return await axios
    .get(`${config.BASE_URL}Common/states`, { params: { CountryId } })
    .then(handleResponse)
    .catch(handleError);
};

export const pullEncounters = async (hospitalId, hospitalNumber, activity) => {
  return await axios
    .get(`${config.BASE_URL}Hospitals/encounters/refresh`, {
      params: { hospitalId, hospitalNumber, activity },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const sendOtpToEmail = async (email) => {
  return await axios
    .post(`${config.BASE_URL}account/otp/generate/email?email=${email}`)
    .then(handleResponse)
    .catch(handleError);
};

export const sendOtpToPhoneNumber = async (phoneNumber) => {
  return await axios
    .post(
      `${config.BASE_URL}account/otp/generate/newphone?phoneNumber=${phoneNumber}`
    )
    .then(handleResponse)
    .catch(handleError);
};

export const verifyOtpForEmailAndPhonenumber = async (otpCode, id) => {
  return await axios
    .post(`${config.BASE_URL}account/otp/verify/${otpCode}?id=${id}`)
    .then(handleResponse)
    .catch(handleError);
};

export const verifyOtpForEmail = async (otpCode, email) => {
  return await axios
    .post(`${config.BASE_URL}account/otp/verify/${otpCode}?email=${email}`)
    .then(handleResponse)
    .catch(handleError);
};

export const switchHospital = async (data) => {
  return await axios
    .post(`${config.BASE_URL}account/hospital/switch`, data)
    .then(handleResponse)
    .catch(handleError);
};
export const recordAppointmentCallTime = async (appointmentId, actionType) => {
  return await axios
    .post(
      `${config.BASE_URL}appointment/${appointmentId}/callhistory?action=${actionType}`
    )
    .then(handleResponse)
    .catch(handleError);
};
export const createWallet = async (patientId, hospitalId, bvn, nin) => {
  return await axios
    .post(`${config.BASE_URL}wallet/create`, undefined, {
      params: { patientId, hospitalId, bvn, nin },
    })
    .then(handleResponse)
    .catch(handleError);
};
export const getWalletDetails = async (patientId, hospitalId) => {
  return await axios
    .get(
      `${config.BASE_URL}wallet?patientId=${patientId}&hospitalId=${hospitalId}`
    )
    .then(handleResponse)
    .catch(handleError);
};
export const retrieveWallet = async (patientId, hospitalId) => {
  return await axios
    .get(
      `${config.BASE_URL}wallet/retrieve?patientId=${patientId}&hospitalId=${hospitalId}`
    )
    .then(handleResponse)
    .catch(handleError);
};
export const getWalletBalance = async (walletNumber, hospitalId) => {
  return await axios
    .get(
      `${config.BASE_URL}wallet/${walletNumber}/balance?hospitalId=${hospitalId}`
    )
    .then(handleResponse)
    .catch(handleError);
};

export const processWalletFinalPayment = async (data) => {
  return await axios
    .post(`${config.BASE_URL}bills/wallet/finalpayment/process`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const processWalletInvoicePayment = async (data) => {
  return await axios
    .post(`${config.BASE_URL}bills/wallet/invoice/process`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const processWalletDepositPayment = async (data) => {
  return await axios
    .post(`${config.BASE_URL}bills/wallet/deposit/process`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const processWalletConsultationPayment = async (data) => {
  return await axios
    .post(`${config.BASE_URL}payment/wallet/process`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const getAvailableVirtualClinicTimeSlot = async (
  hospitalId,
  clinicId,
  date
) => {
  return await axios
    .get(
      `${config.BASE_URL}hospitals/${hospitalId}/timeslots?clinicId=${clinicId}&date=${date}`
    )
    .then(handleResponse)
    .catch(handleError);
};

export const validateNewUserDetails = async (data) => {
  return await axios
    .post(`${config.BASE_URL}account/validate`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const processHospitalReadyCashConfiguration = async (data) => {
  return await axios
    .post(`${config.BASE_URL}hospital-readycash-credentials`, data)
    .then(handleResponse)
    .catch(handleError);
};

export const getAllHospitalsReadyCashConfiguration = async () => {
  return await axios
    .get(`${config.BASE_URL}hospital-readycash-credentials`)
    .then(handleResponse)
    .catch(handleError);
};

export const getHospitalReadyCashConfiguration = async (hospitalId) => {
  return await axios
    .get(`${config.BASE_URL}hospital-readycash-credentials/get-by-hospital`, {
      params: {
        hospitalId: hospitalId,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const verifyHospitalReadyCashPassword = async (hospitalId, password) => {
  return await axios
    .get(
      `${config.BASE_URL}hospital-readycash-credentials/get-by-hospital/${hospitalId}`,
      {
        params: {
          password,
        },
      }
    )
    .then(handleResponse)
    .catch(handleError);
};

export const processHospitalReadyCashWalletActiveToggle = async (id) => {
  return await axios
    .get(`${config.BASE_URL}hospital-readycash-credentials/toggle`, {
      params: {
        id,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const deleteHospitalReadyCashConfiguration = async (id) => {
  return await axios
    .post(`${config.BASE_URL}hospital-readycash-credentials/delete`, "", {
      params: {
        id,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

export const getAllBanksOnReadyCash = async () => {
  return await axios
    .get(`${config.BASE_URL}hospital-readycash-credentials/get-all-banks`)
    .then(handleResponse)
    .catch(handleError);
};
