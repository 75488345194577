import React from "react";
import { Route, Redirect } from "react-router-dom";
import { loggedIn, isAdmin } from "../../modules/auth.module";

const AdminGuard = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn() && isAdmin() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/account/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default AdminGuard;
