import React, { useContext } from "react";
import { message } from "antd";
import { Redirect, Route } from "react-router-dom";
import { AppContext } from "../AppContext";
import { isAdmin, loggedIn } from "../modules/auth.module";

const RegisteredPatientGuard = ({ component: Component, ...rest }) => {
  const { hospitalProfile } = useContext(AppContext);
  if (loggedIn()) {
    if (
      hospitalProfile &&
      hospitalProfile.hospitalNumber &&
      hospitalProfile.hospitalId
    ) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    } else {
      message.error(
        isAdmin()
          ? "You are not allowed to access this resource"
          : "Please you need to register in an hospital"
      );
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect
              to={{
                pathname: "/patient/register",
                state: { from: props.location },
              }}
            />
          )}
        />
      );
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/account/login",
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }
};

export default RegisteredPatientGuard;
