export default function userFinalDetailsLoading(
  userFinalDetailsLoading = true,
  action
) {
  switch (action.type) {
    case "USER_FINAL_PAYMENT_DETAILS_LOADING": {
      userFinalDetailsLoading = action.payload;
      return userFinalDetailsLoading;
    }
    default: {
      return userFinalDetailsLoading;
    }
  }
}
