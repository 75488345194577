import axios from 'axios';
import { getAuthToken, logoutUser, loggedIn } from '../modules/auth.module';

axios.interceptors.request.use(
  request => {
    if (loggedIn()) {
      const token = getAuthToken();
      request.headers['Authorization'] = 'Bearer ' + token;
    }
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, function axiosRetryInterceptor(err) {
  if (err.response.status === 401 || err.response.data.message === '401 Unauthorized') {
    logoutUser();
  }
  return Promise.reject(err);
});