export default function appointmentListLoading(
  appointmentListLoading = true,
  action
) {
  switch (action.type) {
    case "APPOINTMENT_LIST_LOADING": {
      appointmentListLoading = action.payload;
      return appointmentListLoading;
    }
    default: {
      return appointmentListLoading;
    }
  }
}
