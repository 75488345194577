import React, { createContext, useState } from "react";
import { getProfilePicture, getUserProfile } from "./modules/auth.module";
import {
  logoutUser,
  saveUserProfile,
  getHospitalProfile,
  getUserWalletDetails,
} from "./modules/auth.module";

export const AppContext = createContext();

export const AppProvider = (props) => {
  const [profilePicture, setProfilePicture] = useState(getProfilePicture());
  const [user, setUser] = useState(getUserProfile());
  const [hospitalProfile, setHospitalProfile] = useState(getHospitalProfile());
  const [userWalletDetails, setUserWalletDetails] = useState(
    getUserWalletDetails()
  );

  const handleSignOut = (e) => {
    e.preventDefault();
    logoutUser();
  };

  const handleUserSave = (user) => {
    saveUserProfile(user, setUser);
  };

  return (
    <AppContext.Provider
      value={{
        profilePicture,
        setProfilePicture,
        handleSignOut,
        user,
        setUser,
        handleUserSave,
        hospitalProfile,
        setHospitalProfile,
        userWalletDetails,
        setUserWalletDetails,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
