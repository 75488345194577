import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getEmailVerified, isAdmin, loggedIn } from "../../modules/auth.module";

const AuthGuard = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn() ? (
        isAdmin() && window.location.pathname.includes("account") === false ? (
          <Redirect
            to={{
              pathname: "/account/profile",
              state: { from: props.location },
            }}
          />
        ) : getEmailVerified() === false &&
          props.location.pathname !== "/account/email-verification" ? (
          <Redirect
            to={{
              pathname: "/account/email-verification",
              state: { from: props.location, sendOTP: true },
            }}
          />
        ) : (
          <Component {...props} />
        )
      ) : (
        <Redirect
          to={{
            pathname: "/account/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default AuthGuard;
