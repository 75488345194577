export default function appointmentList(appointmentList = [], action) {
  switch (action.type) {
    case "GET_APPOINTMENT_LIST": {
      appointmentList = action.payload;
      return appointmentList;
    }
    default: {
      return appointmentList;
    }
  }
}
