export default function userFinalPaymentDetails(
  userFinalPaymentDetails = {},
  action
) {
  switch (action.type) {
    case "GET_USER_FINAL_PAYMENT_DETAILS": {
      userFinalPaymentDetails = action.payload;
      return userFinalPaymentDetails;
    }
    default: {
      return userFinalPaymentDetails;
    }
  }
}
