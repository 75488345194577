import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Dropdown, Button } from "react-bootstrap";
import {
  getEmailVerified,
  getHospitalLogoFromLocalStorage,
  isAdmin,
} from "../../modules/auth.module";
import { AppContext } from "../../AppContext";

class Sidebar extends Component {
  state = {};
  static contextType = AppContext;

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add className 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/admin", state: "adminMenuOpen" },
      { path: "/patient", state: "myhospitalMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    const { handleSignOut, user, hospitalProfile } = this.context;
    const isVerified =
      hospitalProfile !== null && hospitalProfile.hospitalId !== null;

    return (
      <nav
        className="sidebar sidebar-offcanvas bg-light border-secondary"
        id="sidebar"
      >
        <div className="text-center sidebar-brand-wrapper d-flex align-items-center">
          <a className="sidebar-brand brand-logo" href="/">
            <img src={require("../../../assets/images/logo.svg")} alt="logo" />
          </a>
          {/* <a className="sidebar-brand brand-logo-mini pt-3" href="/">
            <img
              src={require("../../../assets/images/logo-mini.png")}
              alt="logo"
            />
          </a> */}
        </div>
        <div className="d-block overflow-nav">
          {getEmailVerified() && (
            <ul className="nav">
              <li className="nav-item nav-profile not-navigation-link">
                <div className="my-4">
                  <div>
                    <div className="nav-link user-switch-dropdown-toggler p-0 toggle-arrow-hide bg-transparent border-0 w-100">
                      <div
                        className=""
                        style={
                          isAdmin() === false ? {} : { marginTop: "100px" }
                        }
                      >
                        {isAdmin() === false && (
                          <div className="profile-image display-show-sm">
                            <img
                              src={getHospitalLogoFromLocalStorage()}
                              alt=""
                            />
                          </div>
                        )}

                        {/*</div>*/}
                        <div className="mt-120">
                          {isAdmin() === false ? (
                            <>
                              <p
                                className="profile-name text-wrap  mx-2"
                                style={{ lineHeight: 1.5 }}
                              >
                                {isVerified ? (
                                  hospitalProfile.hospitalCode
                                ) : (
                                  <i className="text-muted">
                                    No hospital linked yet
                                  </i>
                                )}{" "}
                                <i
                                  className="mdi mdi-check-underline-circle"
                                  style={{
                                    color: isVerified ? "green" : "grey",
                                  }}
                                ></i>
                              </p>
                              <p className="profile-name">
                                {user !== null ? user.name : ""}
                              </p>
                              <p className="profile-name">
                                {isVerified
                                  ? hospitalProfile.hospitalNumber
                                  : ""}
                              </p>
                            </>
                          ) : (
                            <>
                              <h3 className="text-center fw-bold mb-3">
                                Admin
                              </h3>
                              <p className="profile-name">
                                {user !== null ? user.name : ""}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>

                    <Dropdown.Menu className="preview-list navbar-dropdown"></Dropdown.Menu>
                  </div>
                </div>
              </li>
              {isVerified && (
                <li
                  className={
                    this.isPathActive("/encounters/timeline")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/encounters/timeline">
                    <i className="mdi mdi-alarm menu-icon"></i>
                    <span className="menu-title">Medical History</span>
                  </Link>
                </li>
              )}
              {isVerified && (
                <li
                  className={
                    this.isPathActive("/appointments")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/appointments">
                    <i className="mdi mdi-note-text menu-icon"></i>
                    <span className="menu-title">Appointments</span>
                  </Link>
                </li>
              )}
              {isVerified && (
                <li
                  className={
                    this.isPathActive("/payments") ||
                    this.isPathActive("/invoice-payment") ||
                    this.isPathActive("/deposit-payment") ||
                    this.isPathActive("/final-payment")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/payments">
                    <i className="mdi mdi-account-cash menu-icon"></i>
                    <span className="menu-title">Pay Bills</span>
                  </Link>
                </li>
              )}
              {isVerified && (
                <li
                  className={
                    this.isPathActive("/wallet")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/wallet">
                    <i className="mdi mdi-wallet menu-icon"></i>
                    <span className="menu-title">Wallet</span>
                  </Link>
                </li>
              )}
              {!isVerified && isAdmin() === false && (
                <li
                  className={
                    this.isPathActive("/get-started")
                      ? "nav-item active"
                      : "nav-item"
                  }
                >
                  <Link className="nav-link" to="/get-started">
                    <i className="mdi mdi-note-text menu-icon"></i>
                    <span className="menu-title">Get Started</span>
                  </Link>
                </li>
              )}
              {isAdmin() === false && (
                <>
                  {" "}
                  <li
                    className={
                      this.isPathActive("/switchhospital") ||
                      this.isPathActive("/patient/register") ||
                      this.isPathActive("/patient/verify")
                        ? "nav-item active"
                        : "nav-item"
                    }
                  >
                    <div
                      className={
                        this.state.myhospitalMenuOpen
                          ? "nav-link menu-expanded"
                          : "nav-link"
                      }
                      onClick={() => this.toggleMenuState("myhospitalMenuOpen")}
                      data-toggle="collapse"
                    >
                      <i className="mdi mdi-hospital-building menu-icon"></i>
                      <span className="menu-title">My Hospitals</span>
                      <i className="menu-arrow"></i>
                    </div>
                    <Collapse in={this.state.myhospitalMenuOpen}>
                      <ul className="nav flex-column sub-menu">
                        <li className="nav-item">
                          {" "}
                          <Link
                            className={
                              this.isPathActive("/patient/register")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/patient/register"
                            style={{ fontSize: "16px" }}
                          >
                            Register New
                          </Link>
                        </li>
                        <li className="nav-item">
                          {" "}
                          <Link
                            className={
                              this.isPathActive("/patient/verify")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/patient/verify"
                            style={{ fontSize: "16px" }}
                          >
                            Link Patient Number
                          </Link>
                        </li>
                        <li className="nav-item">
                          {" "}
                          <Link
                            className={
                              this.isPathActive("/patient/switchhospital")
                                ? "nav-link active"
                                : "nav-link"
                            }
                            to="/patient/switchhospital"
                            style={{ fontSize: "16px" }}
                          >
                            Switch Hospital
                          </Link>
                        </li>
                      </ul>
                    </Collapse>
                  </li>
                </>
              )}
              <li
                className={
                  this.isPathActive("/account/profile")
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <Link className="nav-link" to="/account/profile">
                  <i className="mdi mdi-account menu-icon"></i>
                  <span className="menu-title">My Account</span>
                </Link>
              </li>
              {isAdmin() && (
                <li
                  className={
                    this.isPathActive("/admin") ? "nav-item active" : "nav-item"
                  }
                >
                  <div
                    className={
                      this.state.adminMenuOpen
                        ? "nav-link menu-expanded"
                        : "nav-link"
                    }
                    onClick={() => this.toggleMenuState("adminMenuOpen")}
                    data-toggle="collapse"
                  >
                    <i className="mdi mdi-settings menu-icon"></i>
                    <span className="menu-title">Configuration</span>
                    <i className="menu-arrow"></i>
                  </div>
                  <Collapse in={this.state.adminMenuOpen}>
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/admin/hospitals")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/admin/hospitals"
                        >
                          Hospitals
                        </Link>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <Link
                          className={
                            this.isPathActive("/admin/readycash")
                              ? "nav-link active"
                              : "nav-link"
                          }
                          to="/admin/readycash"
                        >
                          Ready Cash Configuration
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              )}
              <li className="nav-item display-hide-sm">
                <Button
                  className="btn btn-themed-outline btn-block nav-link"
                  onClick={handleSignOut}
                >
                  <i className="mdi mdi-exit-to-app menu-icon"></i>
                  <span className="menu-title">Sign Out</span>
                </Button>
              </li>

              {/* <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-television menu-icon"></i>
              <span className="menu-title">Dashboard</span>
            </Link>
          </li> */}
              {/* <li className={this.isPathActive('/basic-ui') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('basicUiMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-crosshairs-gps menu-icon"></i>
              <span className="menu-title">Basic UI Elements</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.basicUiMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/buttons') ? 'nav-link active' : 'nav-link'} to="/basic-ui/buttons">Buttons</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/dropdowns') ? 'nav-link active' : 'nav-link'} to="/basic-ui/dropdowns">Dropdowns</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/basic-ui/typography') ? 'nav-link active' : 'nav-link'} to="/basic-ui/typography">Typography</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive('/form-elements') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/form-elements/basic-elements">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title">Form Elements</span>
            </Link>
          </li>
          <li className={this.isPathActive('/tables') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/tables/basic-table">
              <i className="mdi mdi-table-large menu-icon"></i>
              <span className="menu-title">Tables</span>
            </Link>
          </li>
          <li className={this.isPathActive('/icons') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/icons/font-awesome">
              <i className="mdi mdi-account-box-outline menu-icon"></i>
              <span className="menu-title">Icons</span>
            </Link>
          </li>
          <li className={this.isPathActive('/charts') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/charts/chart-js">
              <i className="mdi mdi-chart-line menu-icon"></i>
              <span className="menu-title">Charts</span>
            </Link>
          </li>
          <li className={this.isPathActive('/user-pages') ? 'nav-item active' : 'nav-item'}>
            <div className={this.state.userPagesMenuOpen ? 'nav-link menu-expanded' : 'nav-link'} onClick={() => this.toggleMenuState('userPagesMenuOpen')} data-toggle="collapse">
              <i className="mdi mdi-lock-outline menu-icon"></i>
              <span className="menu-title">User Pages</span>
              <i className="menu-arrow"></i>
            </div>
            <Collapse in={this.state.userPagesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={this.isPathActive('/user-pages/blank-page') ? 'nav-link active' : 'nav-link'} to="/user-pages/blank-page">Blank Page</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/user-pages/login-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/login-1">Login</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/user-pages/register-1') ? 'nav-link active' : 'nav-link'} to="/user-pages/register-1">Register</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/user-pages/error-404') ? 'nav-link active' : 'nav-link'} to="/user-pages/error-404">404</Link></li>
                <li className="nav-item"> <Link className={this.isPathActive('/user-pages/error-500') ? 'nav-link active' : 'nav-link'} to="/user-pages/error-500">500</Link></li>
              </ul>
            </Collapse>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="http://www.bootstrapdash.com/demo/star-admin-free/react/documentation/documentation.html" rel="noopener noreferrer" target="_blank">
              <i className="mdi mdi-file-outline menu-icon"></i>
              <span className="menu-title">Documentation</span>
            </a>
          </li> */}
            </ul>
          )}
        </div>
      </nav>
    );
  }
}

export default withRouter(Sidebar);
