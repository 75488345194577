import { combineReducers } from "redux";
import appointmentList from "./appointmentList";
import appointmentListLoading from "./appointmentListLoading";
import hospitalDetails from "./hospitalDetails";
import hospitalList from "./hospitalList";
import hospitalListLoading from "./hospitalListLoading";
import userHospitalId from "./userHospitalId";
import hospitalSettings from "./hospitalSettings";
import userFinalPaymentDetails from "./userFinalPaymentDetails";
import userDepositPaymentDetails from "./userDepositPaymentDetails";
import userDepositDetailsLoading from "./userDepositDetailsLoading";
import userFinalDetailsLoading from "./userFinalPaymentLoading";

const rootReducer = combineReducers({
  appointmentList,
  appointmentListLoading,
  hospitalDetails,
  hospitalList,
  hospitalListLoading,
  userHospitalId,
  hospitalSettings,
  userFinalPaymentDetails,
  userFinalDetailsLoading,
  userDepositPaymentDetails,
  userDepositDetailsLoading,
});

export default rootReducer;
