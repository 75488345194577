export default function hospitalSettings(hospitalSettings = [], action) {
  switch (action.type) {
    case "GET_HOSPITAL_SETTINGS": {
      hospitalSettings = action.payload;
      return hospitalSettings;
    }
    default: {
      return hospitalSettings;
    }
  }
}
