const envType = process.env.REACT_APP_ENV_TYPE;
const prodBaseUrl = process.env.REACT_APP_PROD_API_BASE_URL;
const devBaseUrl = process.env.REACT_APP_API_BASE_URL;
const prodMerchantKey =
  process.env.REACT_APP_PROD_FAST_CARE_PAYMENT_MERCHANT_KEY;
const devMerchantKey = process.env.REACT_APP_FAST_CARE_PAYMENT_MERCHANT_KEY;
const callBaseUrl = process.env.REACT_APP_CONSULTATION_CALL_BASE_URL;

export class EnvConfigSecrets {
  static _instance = new EnvConfigSecrets();

  static get instance() {
    return EnvConfigSecrets._instance;
  }

  getEvnType() {
    return envType;
  }

  isProdEvn() {
    return this.getEvnType() === "PROD" ? true : false;
  }

  getApiBaseUrl() {
    if (this.isProdEvn()) {
      return prodBaseUrl + "/api/";
    }
    return devBaseUrl + "/api/";
  }

  getFastCarePaymentMerchantKey() {
    if (this.isProdEvn()) {
      return prodMerchantKey;
    }
    return devMerchantKey;
  }

  getConsultationCallBaseUrl() {
    return callBaseUrl;
  }
}
