import React, { useState } from "react";
import MyAccount from "./MyAccount";

const Navbar = () => {
  const [contentMargin, setContentMargin] = useState(false);

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  return (
    <nav
      className="navbar-app col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row"
      style={{ zIndex: 10 }}
    >
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between px-4">
        <a
          className="navbar-brand brand-logo-mini align-self-center d-lg-none"
          href="/landing"
        >
          <img
            src={require("../../../assets/images/logo-mini.png")}
            alt="logo"
          />
        </a>
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => {
            if (contentMargin) {
              document
                .querySelector(".page-body-wrapper")
                .classList.remove("content-wrapper-sm");
              document
                .querySelector(".page-body-wrapper")
                .classList.add("content-wrapper-m");
            } else {
              document
                .querySelector(".page-body-wrapper")
                .classList.remove("content-wrapper-m");
              // content-wrapper-sm
              document
                .querySelector(".page-body-wrapper")
                .classList.add("content-wrapper-sm");
            }
            setContentMargin(!contentMargin);
            document.body.classList.toggle("sidebar-icon-only");
          }}
        >
          <i className="mdi mdi-menu"></i>
        </button>

        <h4 className="mt-2">
          <b>
            {localStorage.getItem("PageTitle") !== "undefined"
              ? localStorage.getItem("PageTitle")
              : ""}
          </b>
        </h4>

        <div className="d-flex align-items-center">
          <MyAccount />
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
