import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Spinner from "./components/layout/Spinner";
import AuthGuard from "./components/auth/AuthGuard";
import AdminGuard from "./components/auth/AdminGuard";
import RegisteredPatientGuard from "./middleware/RegisteredPatient";

// const Home = lazy(() => import("./pages/Home"));
// const Welcome = lazy(() => import("./pages/WelcomeScreen"));
const VerifyHospital = lazy(() => import("./pages/VerifyHospital"));
// const VerifyOtp = lazy(() => import("./pages/VerifyOtp"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const Timeline = lazy(() => import("./pages/Timeline"));
const Appointments = lazy(() => import("./pages/Appointments"));
const Hospitals = lazy(() => import("./pages/Hospitals"));
const Profile = lazy(() => import("./pages/Profile"));
const Register = lazy(() => import("./pages/RegisterNew"));
const SignUp = lazy(() => import("./pages/SignUp"));
const Login = lazy(() => import("./pages/Login"));
const ResetPasswordRequest = lazy(() => import("./pages/ResetPasswordRequest"));
const GetStarted = lazy(() => import("./pages/GetStarted"));
const Payments = lazy(() => import("./pages/Payments"));
const InvoicePayment = lazy(() =>
  import("./components/payments/Invoice/InvoicePayment")
);
const DepositPayment = lazy(() =>
  import("./components/payments/depositPayment/DepositPayment")
);
const FinalPayment = lazy(() =>
  import("./components/payments/finalPayment/FinalPayment")
);
const SwitchHospital = lazy(() => import("./pages/SwitchHospital"));
const Wallet = lazy(() => import("./pages/Wallet"));
const ReadyCashConfiguration = lazy(() => import("./pages/ReadyCashConfig"));
const EmailVerificationPage = lazy(() => import("./pages/EmailVerify"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          {/* <AuthGuard path="/" exact component={Timeline} /> */}

          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/account/signup" component={SignUp} />
          <Route path="/account/login" component={Login} />
          <Route
            path="/account/reset-password-request"
            component={ResetPasswordRequest}
          />
          
          <AuthGuard path='/account/email-verification' component={EmailVerificationPage} />

          <AuthGuard path="/account/profile" component={Profile} />

          <AuthGuard path="/get-started" component={GetStarted} />

          <AuthGuard path="/patient/verify" component={VerifyHospital} />
          {/* <AuthGuard path="/patient/verify-otp" component={VerifyOtp} /> */}
          <AuthGuard path="/patient/register" component={Register} />

          <RegisteredPatientGuard
            exact
            path="/encounters/timeline"
            component={Timeline}
          />
          <RegisteredPatientGuard
            path="/appointments"
            component={Appointments}
          />
          <RegisteredPatientGuard path="/payments" component={Payments} />
          <RegisteredPatientGuard path="/invoice-payment" component={InvoicePayment} />
          <RegisteredPatientGuard path="/deposit-payment" component={DepositPayment} />
          <RegisteredPatientGuard path="/final-payment" component={FinalPayment} />

          <AuthGuard
            path="/patient/switchHospital"
            component={SwitchHospital}
          />

          <RegisteredPatientGuard path="/wallet" component={Wallet} />

          <AdminGuard path="/admin/hospitals" component={Hospitals} />
          <AdminGuard
            path="/admin/readycash"
            component={ReadyCashConfiguration}
          />

          <Redirect to="/encounters/timeline" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
