export default function userDepositPaymentDetails(
  userDepositPaymentDetails = {},
  action
) {
  switch (action.type) {
    case "GET_USER_DEPOSIT_PAYMENT_DETAILS": {
      userDepositPaymentDetails = action.payload;
      return userDepositPaymentDetails;
    }
    default: {
      return userDepositPaymentDetails;
    }
  }
}
